import api from '@/libs/axios'

export default {
  async create(payload) {
    const response = await api.post('/admin/stages', payload)
    return response.data.data.new_document || null
  },
  async getList(params) {
    let response = await api.get('/admin/stages', {
      params,
    })
    return response.data.data || null
  },
  async get(params) {
    let response = await api.get('/admin/stages/detail', {
      params,
    })
    return response.data.data.document || null
  },
  async update(payload) {
    let response = await api.put('/admin/stages', payload)
    return response
  },
  async delete(payload) {
    const response = await api.delete('/admin/stages', {
      data: payload,
    })
    return response
  },
  async getAll(params) {
    const response = await api.get('/admin/stages/all', { params })
    return response.data.data || null
  },

  async getGrammarCardList(params){
    let response = await api.get('/admin/grammar-cards', {
      params,
    })
    return response.data.data || null
  }
}
