<template>
  <div class="page-content" v-if="isReady">
    <page-breadcrumb title="Arena Stage" />
    <b-card>
      <b-row align-v="center">
        <b-col>
          <form-generator :model="query" :schema="query_schema" />
        </b-col>
        <!-- <div class="ml-auto mt-50 mr-1">
          <data-create-popup :schema="create_stage_schema"
            :default-data="{ type: null }"
            :create-data-fn="createItem"
            @create="getAll"
          />
        </div> -->
      </b-row>
    </b-card>
    <section class="mt-1" id="draggable-cards">
      <b-row>
        <b-col v-for="(item, index) in stages" :key="index" class="col-lg-2 col-md-2 col-12">
          <b-link :to="{ name: 'arena-stage-detail', params: { stage_id: item._id } }" :id="`stage_${index+1}`">
            <b-card class="text-center">
              <div>
                Stage {{ item["stage_number"] + 1 }}{{ item["is_published"]? '' : ' [inactivated]' }}
              </div>
              <div>
                {{ convertTypeToText(item.type) }}
              </div>
            </b-card>
          </b-link>
        </b-col>
      </b-row>
    </section>

    <b-pagination class="mt-auto" pills size="lg" align="center" v-model="current_page" :total-rows="total_items"
      :per-page="items_perpage" />

  </div>
</template>

<script>
import { buildSelectOptions } from '@core/utils/index'

import { STAGE_TYPES, VOCAB_TYPES, STATUS } from '../constant'
import service from "../service";

const stage_type_options = buildSelectOptions(STAGE_TYPES)
const game_type_options = buildSelectOptions(VOCAB_TYPES)
const PUBLISH_OPTION = buildSelectOptions(STATUS);
const query_schema = [
  {
    cols: 6,
    fields: [{ field: 'is_published', label: 'Status', input_type: 'select', options: PUBLISH_OPTION }]
  },
  {
    cols: 6,
    fields: [{ field: 'stage_number', label: 'Stage Number', input_type: 'number' }]
  }
]
const create_stage_schema = [
  {
    cols: 12,
    fields: [
      { field: 'type', label: 'Type', input_type: 'select', options: stage_type_options },
      {
        field: 'game_type',
        label: 'Vocab Type',
        input_type: 'select',
        options: game_type_options,
        displayCond(model) { return model.stage_type === STAGE_TYPES.VOCAB }
      },
    ]
  }
]

export default {
  data() {
    return {
      PUBLISH_OPTION,
      create_stage_schema,
      query_schema,
      current_page: 1,
      total_items: 0,
      items_perpage: 30,
      stages: null,
      isReady: false,
      query: {},
    };
  },
  watch: {
    current_page() {
      this.getAll();
    },
    query: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
  },
  created() {
    this.getAll = _.debounce(this.getAll, 300);
    this.getAll();
  },
  methods: {
    async getAll() {
      let query = {...this.query};
      if(query.stage_number) {
        query.stage_number -= 1;
      } else {
        delete query.stage_number;
      }
      let response = await service.getList({
        query,
        page: this.current_page,
        limit: this.items_perpage,
      });
      if(response){
        this.stages = response.list;
        this.total_items = response.total
      }
      this.isReady = true;
    },
    async createItem(data) {
      let new_data = await service.create({ ...data, display_order: this.total_items });
      if (new_data) {
        this.$router.push({
          name: "arena-stage-detail",
          params: { stage_id: new_data._id },
        });
      }
    },
    convertTypeToText(type){
      let stage_type = stage_type_options.find(item => item.value === type);
      return stage_type?.text || 'unkown'
    },
  },
};
</script>
